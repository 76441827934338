
// import libs
// import './libs/cookieBar';

var $header = $('#header');
var headerHeight = $header.outerHeight();

// import components
import './components/ie-detection.js';
import './components/menu.js';
// import './components/modal.js';

// init
// $('.lazy').Lazy({
//     // your configuration goes here
//     scrollDirection: 'vertical',
//     effect: 'fadeIn',
//     effectTime: 150,
//     visibleOnly: true,
//     threshold: 500,
//     bind: 'event',
//     onError: function(element) {
//         console.log('error loading ' + element.data('src'));
//     }
// });

// ----------------------------------------
//  scroll to
var $scrollToItems = $('.scrollTo');
$.each($scrollToItems, function() {
    $(this).on('click', function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        if (typeof href === 'undefined') {
            href = $(this).attr('data-href');
        }
        var offset = $(this).attr('data-offset');

        // if booking strip scroll to
        if ($(this).hasClass('scrolling-strip')) {
            const mq = window.matchMedia( "(max-width: 576px)" );
            if (mq.matches) {
                offset = 0;
            }
        }

        var $target = $(href);
        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top - headerHeight + ((typeof offset !== 'undefined') ? parseInt(offset) : 0)
            }, {
                duration: 300,
                easing: 'swing'
            });
        }
    });
});
// ----------------------------------------

// scripts here
// ----------------------------------------

// Bootstrap dropdown
// $('.dropdown-toggle').dropdown();

// collapse
// $('.collapse__toggle, .collapse__toggle > label').on('click', function(e) {
//     $(this).closest('.collapse').toggleClass('show');
// });

$('#searchBtn').on('click', function(e) {
    $('body').addClass('show-search');
    $('#searchInput').focus();
});

$(document).on('click', function(e) {
    if (!$(e.target).closest('.search__container').length && !$(e.target).closest('#searchBtn').length) {
        $('body').removeClass('show-search');
    }
});

// $('.form-row.disabled').on('click', '.btn', function(e) {
//     $(this).closest('.form-row').removeClass('disabled');
// });

if ($('.page--profile').length) {
    var $profileMenu = $('#profileMenu');
    $profileMenu.find('.toggle').on('click', function(e) {
        $profileMenu.toggleClass('active');
        $profileMenu.find('li:not(.toggle)').toggleClass('d-none');
    });
}

// tips on detail
$('#tips').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
  });

// invoicing address - show/hide
$('[data-other-address-toggle]').on('change', function() {
    var targetDiv = $('#' + $(this).data('other-address-toggle'));
    if($(this).val() === '1') {
        targetDiv.addClass('d-none');
    } else {
        targetDiv.removeClass('d-none');
    }
}).change();


// check if strip exists
if ($('#header .strip').length) {
    $('body').addClass('has-strip');
}

$('#header').on('click', '.strip__close', function(e) {
    // e.preventDefault();
    $('body').removeClass('has-strip');
});